import React from 'react'
import debug from 'debug'
import Facemask from 'AppSrc/components/icons/facemask.svg?component'
import './style.css'

debug.enable('metadatafacemask/component:*')
// const log = debug('metadatafacemask/component:log')
// const info = debug('metadatafacemask/component:info')
// const error = debug('metadatafacemask/component:error')

type Props = { regItems: RegistrantItemsType }

const MetaDataFaceMask = ({ regItems }: Props) => {
  const requiresFaceMask = Object.values(regItems).find(val => val.requires_face_mask)

  if (!requiresFaceMask) {
    return null
  }

  return (
    <div className="metadata face-mask-icon">
      <Facemask />
    </div>
  )
}

export default MetaDataFaceMask

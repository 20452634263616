import dayjs from 'dayjs'
import config from '../config'
import debug from 'debug'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

config.debug && debug.enable('itemsHelpers:*')
const log = debug('itemsHelpers:log')
// const info = debug('itemsHelpers:info')
// const error = debug('itemsHelpers:error')

export const getItemsEventIds = (items: DbItemsType) => {
  const eventIds = {} as { [id: string]: boolean }
  Object.values(items).forEach(item => {
    if (item.event) {
      eventIds[item.event.id] = true
      if (item.event.event_prov_id) eventIds[item.event.event_prov_id] = true
      if (item.event.event_post_id) eventIds[item.event.event_post_id] = true
      ;(item.event.event_products_info || []).forEach(info => {
        info.product_event_ids.forEach(id => {
          eventIds[id] = true
        })
      })
    }
  })

  return eventIds
}

/*
export const hasOrderOnlyValidEventIds = (
  lineItems: wcItemType[],
  eventIds: { [id: string]: boolean },
  settings: settingsType
) => {
  const settingsEventsDateRangeUnix =
    settings.events.start_date && settings.events.end_date
      ? {
          start_date: dayjs(settings.events.start_date).tz(tz, true).unix(),
          end_date: dayjs(settings.events.end_date).tz(tz, true).add(1, 'day').unix(),
        }
      : undefined

  for (const item of lineItems) {
    const metadataEventId = item.meta_data.find(m => m.key === '_event_id')
    if (
      metadataEventId &&
      typeof metadataEventId.value === 'string' &&
      !eventIds[metadataEventId.value]
    ) {
      return false
    }

    const metadataEventStartDateTimes = item.meta_data.find(m => m.key === '_event_start_datetimes')
    if (metadataEventStartDateTimes && settingsEventsDateRangeUnix) {
      const eventStartDateTimes = metadataEventStartDateTimes.value
      if (Array.isArray(eventStartDateTimes)) {
        const foundMatch = eventStartDateTimes.some(
          eventStartDateTime =>
            settingsEventsDateRangeUnix.start_date <= eventStartDateTime &&
            eventStartDateTime <= settingsEventsDateRangeUnix.end_date
        )
        if (!foundMatch) {
          return false
        }
      }
    }
  }

  return true
}
*/

const tz = 'America/Los_Angeles'

export const hasOrderWithMatchingDate = (
  order: wcOrderType,
  eventIds: { [id: string]: boolean },
  settings: settingsType
) => {
  const validOrderLineItemIds = getOrderLineItemsWithMatchingDate(order, eventIds, settings)
  return validOrderLineItemIds.length > 0
}

export const getOrderLineItemsWithMatchingDate = (
  order: wcOrderType,
  eventIds: { [id: string]: boolean },
  settings: settingsType
) => {
  // order.date_created is in the site timezone already
  const dateCreated = dayjs(order.date_created).unix()
  log('... order date created', order.date_created)
  const lineItems = order.line_items

  const validOrderLineItemIds: number[] = []

  const settingsEventsDateRangeUnix =
    settings.events.start_date && settings.events.end_date
      ? {
          start_date: dayjs(settings.events.start_date).tz(tz, true).unix(),
          end_date: dayjs(settings.events.end_date).tz(tz, true).add(1, 'day').unix(),
        }
      : undefined

  for (const item of lineItems) {
    if (!config.hasEvents) {
      if (
        settingsEventsDateRangeUnix &&
        settingsEventsDateRangeUnix.start_date <= dateCreated &&
        dateCreated <= settingsEventsDateRangeUnix.end_date
      ) {
        validOrderLineItemIds.push(item.id)
      }
      continue
    }

    const metadataEventId = item.meta_data.find(m => m.key === '_event_id')
    if (
      metadataEventId &&
      typeof metadataEventId.value === 'string' &&
      eventIds[metadataEventId.value]
    ) {
      const metadataEventStartDateTimes = item.meta_data.find(
        m => m.key === '_event_start_datetimes'
      )
      const eventStartDateTimes = metadataEventStartDateTimes?.value
      if (Array.isArray(eventStartDateTimes) && settingsEventsDateRangeUnix) {
        const foundMatch = eventStartDateTimes.some(eventStartDateTime => {
          return (
            settingsEventsDateRangeUnix.start_date <= eventStartDateTime &&
            eventStartDateTime <= settingsEventsDateRangeUnix.end_date
          )
        })
        if (foundMatch) {
          validOrderLineItemIds.push(item.id)
        }
      } else {
        // assume valid if we have no settings date range or start date/times metadata
        log(
          '... No valid settings date range or event start date/times metadata, letting order line item through'
        )
        validOrderLineItemIds.push(item.id)
      }
    }
  }

  return validOrderLineItemIds
}

export const getEventRequiresFaceMask = ({
  productId,
  variationId,
  slugOfProductItem,
  items,
}: {
  productId: RegistrantItemType['item_product_id']
  variationId: RegistrantItemType['item_variation_id']
  slugOfProductItem: { [id: string]: string }
  items: DbItemsType
}): boolean => {
  const pid = variationId && variationId !== '0' ? variationId : productId
  const slug = slugOfProductItem[pid]
  const evt = items[slug].event

  return evt?.requires_face_mask ?? false
}

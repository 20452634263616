import config from '../config'
import debug from 'debug'

config.debug && debug.enable('helpers:*')
// const log = debug('errorHelpers:log')
// const info = debug('errorHelpers:info')
const error = debug('errorHelpers:error')

export const getThrowError = (err: any) => {
  if (typeof err === 'string') return err

  error(err)
  if (err instanceof Error || (err && typeof err === 'object' && 'message' in err)) {
    return err.message
  }

  return 'An error happened'
}
